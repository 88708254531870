import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import EnuitDialog from '../../components/dialog/EnuitDialog';
import IApiResource, { IUpdatedApiResource } from '../../interfaces/apiResources/IApiResource';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IApiResourceClaim from '../../interfaces/apiResources/IApiResourceClaim';
import Autocomplete from '@mui/material/Autocomplete';
import IClaim from '../../interfaces/claims/IClaim';
import useAxios from '../../hooks/useAxios';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ApiResources from './ApiResources';

interface INewApiResourceDialogProps {
  open: boolean;
  onClose: () => void;
  onAdd: (newApiResource: IApiResource, newApiResourceClaims: IApiResourceClaim[]) => void;
  onUpdate: (updatedApiResource: IUpdatedApiResource, updatedResourceClaims: IApiResourceClaim[]) => void;
  onDelete: (apiResourceName: string) => void;
  //onDisable: (updates: Partial<IApiResource>) => void;
  updateResource: IApiResource | undefined;
  updateResourceClaims: IApiResourceClaim[] | undefined;
  claims: IClaim[];
  setAlertMessage: React.Dispatch<React.SetStateAction<string>>;
}

function NewApiResource(props: INewApiResourceDialogProps) {
  const { open, onClose, onAdd, onUpdate, updateResource, updateResourceClaims, claims, onDelete, setAlertMessage } = props;
  const [name, setName] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [resourceClaims, setResourceClaims] = useState<IClaim[]>([])
  const [errorText, setErrorText] = useState('');
  const originalResourceClaims = useRef<IClaim[]>([]);
  const axios = useAxios();
  const isDialogUpdating = updateResource !== undefined;
  const [enabled, SetEnabled] = useState(false);

  useEffect(() => {
    if (isDialogUpdating) {
      setName(updateResource.name);
      setDisplayName(updateResource.displayName ?? '');
      SetEnabled(updateResource.enabled)

      const existingClaims = claims.filter(claim => updateResourceClaims?.map(claim => claim.type).includes(claim.type));
      setResourceClaims(existingClaims ?? []);
      originalResourceClaims.current = existingClaims;
    } else {
      setName('');
      setDisplayName('');
      setResourceClaims([]);
    }

    setErrorText('');
  }, [open]);

  const handleAddOrUpdate = async () => {
    if (name === '') {
      setErrorText('Name is required');
      return;
    }

    try {

      const apiResource: IApiResource = {
        name: name,
        displayName: displayName,
        description: undefined,
        enabled : true
      }

      if (isDialogUpdating) {
        const originalNames = originalResourceClaims.current.map(a => a.type);
        const currentNames = resourceClaims.map(a => a.type);

        const newlyDeletedClaims = originalResourceClaims.current.filter(claim => !currentNames.includes(claim.type));
        const claimsToDelete = newlyDeletedClaims.map(claim => ({apiName: name, type: claim.type}));
        for (const claim of claimsToDelete) {
          await axios.delete('admin/ApiClaim', {data: claim});
        }

        const newlyAddedClaims = resourceClaims.filter(claim => !originalNames.includes(claim.type));
        const claimsToAdd = newlyAddedClaims.map(claim => ({apiName: name, type: claim.type}));
        await axios.post('admin/ApiClaim', claimsToAdd);
        
        apiResource.enabled = enabled;
        const response = await axios.put<IApiResource>('admin/ApiResource?key=' + updateResource.name, { ...apiResource })
        onUpdate({ ...response.data, previousName: updateResource.name }, resourceClaims.map(claim => ({apiName: name, type: claim.type})));
        setAlertMessage('The api resource was updated successfully.');
      } else {
        const resourceResponse = await axios.post<IApiResource>('admin/ApiResource', { ...apiResource });

        const newClaims: IApiResourceClaim[] = resourceClaims.map(a => { return {apiName: name, type: a.type}})
        await axios.post<IApiResourceClaim[]>('admin/ApiClaim', newClaims);
        onAdd(resourceResponse.data, newClaims);
        setAlertMessage('The api resohandleDeleteurce was created successfully.');
      }

      onClose();
    } catch (error) {
      setAlertMessage('An error occurred while saving the api resource.');
      console.log('An error occurred while saving the api resource.', error);
    }
  }

  const handleDelete  = async () => {
    try {
      await axios.delete(`admin/ApiResource?key=${updateResource!.name}`);
      onDelete(updateResource!.name);
      onClose();
      setAlertMessage('The api resource was deleted successfully.');
    } catch (error) {
      setAlertMessage('An error occurred while deleting the api resource.');
      console.log('An error occurred while deleting the api resource.', error);
    }
  }
     
  const handleDisable  = async (isChecked:boolean ) =>
  {   
       SetEnabled(isChecked);
          // if(updateResource instanceof IApiResource)
          //  updateResource.enabled= enable;
  }

  // const handleDisable  = async (updates: Partial<IApiResource>) => {
  //   try {
  //     await axios.delete(`admin/ApiResource?key=${updateResource!.name}`);
  //     onDelete(updateResource!.name);
  //     onClose();
  //     setAlertMessage('The api resource was disabled successfully.');
  //   } catch (error) {
  //     setAlertMessage('An error occurred while disabling the api resource.');
  //     console.log('An error occurred while disabling the api resource.', error);
  //   }
  // }


  return (
    <EnuitDialog
      open={open}
      onClose={onClose}
      onOkClick={handleAddOrUpdate}
      title={isDialogUpdating ? 'Update Api Resource' : 'New Api Resource'}
      showDelete={isDialogUpdating}
      onDeleteClick={handleDelete}
    >
      <Stack direction='column' spacing={2}>
        <Box>
          <Typography>Name</Typography>
          <TextField
            fullWidth
            size='small'
            value={name}
            onChange={e => setName(e.target.value)}
            error={errorText !== ''}
            helperText={errorText}
            disabled={isDialogUpdating}
          />
        </Box>
        <Box>
          <Typography> Display Name</Typography>
          <TextField
            fullWidth
            size='small'
            value={displayName}
            onChange={e => setDisplayName(e.target.value)}
          />
        </Box>
        <Box>
          <Typography>Claim Types</Typography>
          <Autocomplete
            autoHighlight
            multiple
            disableClearable
            disableCloseOnSelect
            filterSelectedOptions
            id='api-resource-claim-types'
            options={claims}
            value={resourceClaims}
            onChange={(e, value, r, d) => setResourceClaims(value)}
            getOptionLabel={option => `${option.description} - ${option.type}`}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size='small'
              />
            )}
          />
        </Box>
        { isDialogUpdating && <FormControlLabel  
          control={
            <Checkbox 
              checked={enabled}
              value={enabled}
              onChange={(e) => {SetEnabled(e.target.checked)}}
               
            />
          } 
          label='Enabled' 
        /> }
      </Stack>
    </EnuitDialog>
  )
}

export default NewApiResource