import React, { useState, useEffect } from 'react'
// MUI
import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// Other
import IClient from '../../interfaces/clients/IClient';
import useAxios from '../../hooks/useAxios';
import NewClientDialog from './NewClientDialog';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../components/PageHeader/PageHeader';
import List from '../../components/List/List';
import ax from 'axios';

export function sortByClientId(a: IClient, b: IClient) {
  if (a.clientId.toLocaleLowerCase() < b.clientId.toLocaleLowerCase()) {
    return -1;
  } else if (b.clientId.toLocaleLowerCase() < a.clientId.toLocaleLowerCase()) {
    return 1;
  }
  return 0;
}

function Clients() {
  const [search, setSearch] = useState('');
  const [clients, setClients] = useState<IClient[]>([]);
  const [open, setOpen] = useState(false);
  const [showDisabledClient, setShowDisabledClient] = useState(false);
  const axios = useAxios();
  const navigate = useNavigate();

  useEffect(() => {
    loadData();
  }, [])

  const loadData = async () => {
    try {
      const response = await axios.get<IClient[]>('admin/Client');
      setClients(response.data);
    } catch (error) {
      if (ax.isAxiosError(error)) {
        if (error.response?.status === 403) {
          navigate('/unauthorized');
          return;
        }
      }
      console.log('an error occurred while loading the clients', error);
    }
  }

  function calcFilteredClients() {
   
    if (search === '') {
      return !showDisabledClient ?  clients.filter(c=>c.enabled) : clients;
    }

    return !showDisabledClient ?
     (clients
      .filter(client => ((
        client.clientId.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        || client.clientName?.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        || client.description?.toLocaleLowerCase().includes(search.toLocaleLowerCase())) && client.enabled )
      ))
      :
      (clients
        .filter(client => (
          client.clientId.toLocaleLowerCase().includes(search.toLocaleLowerCase())
          || client.clientName?.toLocaleLowerCase().includes(search.toLocaleLowerCase())
          || client.description?.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
        ));
  }

  const getFilteredClients = React.useCallback(calcFilteredClients, [search, clients,showDisabledClient]);

  function addNewClient(client: IClient) {
    setClients(prev => [...prev].concat(client));
    navigate(client.clientId, { state: { clientManagement: client } });
  }

  return (
    <>
      <NewClientDialog open={open} onClose={() => setOpen(false)} addClient={addNewClient} />
      <Stack id='clients-page' direction='column' spacing={2}>
        <PageHeader
          pageName='Clients'
          searchValue={search}
          onSearchFieldChange={setSearch}
          button={{ buttonText: 'Add Client', handleButtonClick: () => setOpen(true), startIcon: <AddIcon /> }}
          chekbox= {{ label: 'Show disabled clients', handleCheckboxClick : () =>{}, enable: true}}
          onChekboxChange={setShowDisabledClient}
        />
        <List
          listData={
            getFilteredClients().sort((a, b) => sortByClientId(a, b)).map(client => ({
              title:   client.enabled ? client.clientId : client.clientId +  ' (disabled)',
              bodyText: client.clientName ?? '',
              action: {
                type: 'primary',
                itemId: client.clientId,
                actionText: 'View Client',
                actionHandler: () => navigate(client.clientId, { state: { clientManagement: client } }),
                variant: 'outlined',
                endIcon: <ArrowForwardIcon />,
                tileClickEnabled: true,
                buttonVisible: false
              }
            }))
          }
        />
      </Stack>
    </>
  );
}

export default Clients