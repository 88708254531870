import React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export type listItem = {
  title: string;
  subtitle?: string;
  bodyText?: string;
  body?: React.ReactNode;
  action?: {
    actionText: string;
    itemId: string;
    actionHandler: (id: string) => void;
    type: 'primary' | 'error';
    variant?: 'outlined' | 'contained';
    endIcon?: React.ReactNode
    tileClickEnabled?: boolean;
    buttonVisible?: boolean;
  };
  tags?: Array<string>;
};

export interface ListProps {
  listData: Array<listItem> | undefined,
  minHeight?: string;
};

const ListContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
}));

const ListItemContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '64px',
  overflow: 'hidden',
  rowSpacing: 4,
  '& .action': {
    opacity: 0,
  },
  '&:hover': {
    boxShadow: theme.shadows[4],
    '& .action': {
      opacity: 1,
    }
  }
}));

const ListItem = (props: { listItemData: listItem, minHeight: string }) => {
  const { listItemData, minHeight } = props;
  const bodyText = listItemData.bodyText;
  const Body = listItemData.body;

  const enableTileClick = props.listItemData.action?.tileClickEnabled ?? false;
  const buttonVisible = props.listItemData.action?.buttonVisible ?? true;
  return (
    <ListItemContainer 
      elevation={1} 
      sx={{ '&:hover': { cursor:  enableTileClick ? 'pointer' : ''}, minHeight: minHeight }} 
      onClick={enableTileClick ? () => listItemData.action!.actionHandler(listItemData.action!.itemId) : () => null} 
    >
      <Stack direction={{xs: 'column', lg: 'row'}} justifyContent='space-between' alignItems={{lg: 'center'}}  sx={{p: {xs: '0 0 16px 0', lg: '0 16px 0 0'}}}>
        <Box p='12px 16px' minHeight='56px'>
          <Stack direction='row' alignItems='center' spacing={1}>
            <Typography sx={{flexWrap: 'wrap'}} variant="subtitle2">{listItemData.title}</Typography>
            {listItemData.subtitle
              ? <Typography variant="subtitle2">{listItemData.subtitle}</Typography>
              : ''
            }
            <Box sx={{ flex: 1 }} />
          </Stack>
          {bodyText !== undefined && <Typography variant="body2">{bodyText}</Typography>}
        </Box>
        { listItemData.action !== undefined && buttonVisible &&
          <span>
            <Button
              color={listItemData.action.type}
              variant={listItemData.action.variant??"text"}
              onClick={() => listItemData.action!.actionHandler(listItemData.action!.itemId)}
              className="action"
              endIcon={listItemData.action.endIcon}
              size='small'
              sx={{m: {xs: '12px 16px', lg: '0'}}}
            >
              {listItemData.action.actionText}
            </Button>
          </span>
        }
      </Stack>
      {Body !== undefined && Body}
    </ListItemContainer>
  )
};

const List = (props: ListProps) => {
  const { listData, minHeight = '64px' } = props;
  return (
    <ListContainer>
      <Grid container spacing={{ xs: 2, md: 4 }} sx={{width: '100vw'}} >
        <>
          {listData?.map((listItem, index) => (
            <Grid xs={12} sm={12} md={6} lg={6} xl={4} key={index} >
              <ListItem listItemData={listItem} minHeight={minHeight} />
            </Grid>
          ))}
        </>
      </Grid>
    </ListContainer>
  )
}

export default List;