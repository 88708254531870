import React, { useEffect, useState, useRef, useMemo, useContext } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate, useParams } from 'react-router-dom';
import { 
  Box, 
  Button, 
  Checkbox, 
  List, 
  ListItem, 
  ListItemButton, 
  ListItemIcon, 
  ListItemText, 
  Paper, 
  Stack, 
  SxProps, 
  TextField, 
  Theme, 
  Typography 
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import useAxios from '../../hooks/useAxios';
import IRole from '../../interfaces/roles/IRole';
import { isEqual } from 'lodash';
import IUser from '../../interfaces/users/IUser';
import DeleteForever from '@mui/icons-material/DeleteForever';
import AddNewUserRole from './AddNewUserRole';
import Searchfield from '../../components/Searchfield/Searchfield';
import UserDetailsDialog from './UserDetailsDialog';
import { EnuitAlertContext } from '../../components/alert/EnuitAlert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const emptyRole: IRole = {
  name: '',
  description: ''
}

function UserRoles() {
  const { name } = useParams();
  const axios = useAxios();

  const [role, setRole] = useState<IRole>(emptyRole);
  const [roleChanged, setRoleChanged] = useState(false);
  const [allUsers, setAllUsers] = useState<IUser[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [checkedUsers, setCheckedUsers] = useState<string[]>([]);
  const [selectedUser, setSelectedUser] = useState<IUser | undefined>();
  const [newUserRoleOpen, setNewUserRoleOpen] = useState(false);
  const [userDetailsOpen, setUserDetailsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const originalRole = useRef<IRole>(emptyRole);
  const [alertMessage, setAlertMessage] = useContext(EnuitAlertContext);
  const navigate = useNavigate();

  useEffect(() => {
    loadRole();
    loadAllUsers();
    loadUsers();
  }, []);

  const loadRole = async () => {
    try {
      const response = await axios.get<IRole>('admin/Role/name?roleName='+name!);
      setRole(response.data);
      originalRole.current = response.data;
    } catch (error) {
      console.log('An error occurred while loading the role', error);
    }
  }

  const loadAllUsers = async () => {
    try {
      const response = await axios.get<IUser[]>('admin/User');
      setAllUsers(response.data);
    } catch (error) {
      console.log('An error occurred while loading the users', error);
    }
  }

  const loadUsers = async () => {
    try {
      const response = await axios.get<IUser[]>('admin/Role/Users?roleId='+name);
      setUsers(response.data.filter(user=>!user.isLockedOut));
    } catch (error) {
      console.log('An error occurred while loading the users', error);
    }
  }

  const handleSaveClick = async () => {
    try {
      await axios.put(`admin/Role?roleName=${role.name}&description=${role.description}`);
      originalRole.current = role;
      setRoleChanged(false);
      setAlertMessage('The role was updated successfully.');
    } catch (error) {
      console.log('An error occurred while updating the role');
    }
  }

  useEffect(() => {
    setRoleChanged(!isEqual(role, originalRole.current));
  }, [role]);

  const paperSx: SxProps<Theme> = {
    p: 2
  }

  const handleCheckboxClick = (user: IUser) => {
    if (checkedUsers.includes(user.id)) {
      setCheckedUsers([...checkedUsers].filter(userId => userId !== user.id))
    } else {
      setCheckedUsers([...checkedUsers].concat(user.id));
    }
  }

  const sortByUserName = (a: IUser, b: IUser) => {
    const aName = (a.name ?? a.userName).toLocaleLowerCase();
    const bName = (b.name ?? b.userName).toLocaleLowerCase();

    if (aName > bName) {
      return 1;
    } else if (bName > aName) {
      return -1;
    }
    return 0;
  }

  const availableUsers = useMemo(() => {
    const existingUserIds = users.map(user => user.id);
    const allButExistingUsers = allUsers.filter(user => !existingUserIds.includes(user.id));
    return allButExistingUsers.sort((a,b) => sortByUserName(a,b));
  }, [users, allUsers]);

  const displayUsers = useMemo(() => {
    if (searchValue === '') {
      return users.sort((a,b) => sortByUserName(a,b));
    }

    const search = searchValue.toLocaleLowerCase();
    const filteredUsers = users.filter(user => (
      user.name.toLocaleLowerCase().includes(search) ||
      user.userName.toLocaleLowerCase().includes(search) ||
      user.email?.toLocaleLowerCase().includes(search)
    ))

    return filteredUsers.sort((a,b) => sortByUserName(a,b));
  }, [users, searchValue]);

  const handleAdd = (users: IUser[]) => {
    setUsers(prev => [...prev].concat(users));
  }

  const handleDelete = async () => {
    try {
      const usersToRemove = users.filter(user => checkedUsers.includes(user.id));
      await axios.delete('admin/Role/Users?roleId='+name, {data: usersToRemove});
      setUsers(prev => [...prev].filter(user => !checkedUsers.includes(user.id)));
      setCheckedUsers([]);
      setAlertMessage('The selected users were successfully removed from this role.');
    } catch (error) {
      setAlertMessage('An error occurred while removing users from this role.');
      console.log('An error occurred while removing users from this role', error);
    }
  }

  return (
    <>
      <AddNewUserRole
        open={newUserRoleOpen}
        onClose={() => setNewUserRoleOpen(false)}
        users={availableUsers.filter(user =>!user.isLockedOut)}
        roleName={name!}
        onAdd={handleAdd}
      />
      <UserDetailsDialog
        open={userDetailsOpen}
        onClose={() => setUserDetailsOpen(false)}
        user={selectedUser}
      />
      <Button onClick={() => navigate('/roles')} startIcon={<ArrowBackIcon />} id='test' size='small'>Back to roles</Button>
      <Typography variant='subtitle1' sx={{mb: 2}}>User Roles</Typography>
      <Grid container spacing={2}>
        <Grid xs={12} md={6}>
          <Paper elevation={3} sx={{...paperSx, position: 'sticky', top: '0px'}}>
            <Stack direction='column' spacing={2}>
              <Typography variant='subtitle1'>Role</Typography>
              <Box>
                <Typography>Name</Typography>
                <TextField
                  size='small'
                  fullWidth
                  value={role?.name}
                  disabled
                />
              </Box>
              <Box>
                <Typography>Description</Typography>
                <TextField
                  size='small'
                  fullWidth
                  value={role?.description}
                  onChange={e => setRole(prev => ({...prev, description: e.target.value}))}
                />
              </Box>
              <Box display='flex' justifyContent='flex-end'>
                <Button onClick={handleSaveClick} variant='contained' startIcon={<SaveIcon />} disabled={!roleChanged}>Save</Button>
              </Box>
            </Stack>
          </Paper>
        </Grid>
        <Grid xs={12} md={6}>
          <Paper elevation={3} sx={paperSx}>
            <Stack direction='column' spacing={2}>
              <Typography variant='subtitle1'>Users</Typography>
              <Stack direction={{xs: 'column', lg: 'row'}} spacing={2}>
                <Button variant='contained' startIcon={<AddIcon />} onClick={() => setNewUserRoleOpen(true)}>Add user</Button>
                <Button variant='contained' color='error' startIcon={<DeleteForever />} onClick={handleDelete}>Remove selected</Button>
                <Searchfield value={searchValue} onChange={e => setSearchValue(e.target.value)} placeholder='Search users' />
              </Stack>
              <List dense disablePadding>
                {displayUsers.map(user => (
                  <ListItem 
                    key={user.id}
                    disablePadding
                    dense
                    sx={{
                      '.view-user-button': {
                        opacity: 0
                      },
                      '&:hover': {
                        '.view-user-button': {
                          opacity: 1
                        }                        
                      }
                    }}
                  >
                    <ListItemButton role={undefined}  dense>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={checkedUsers.includes(user.id)}
                          tabIndex={-1}
                          disableRipple
                          size='small'
                          onClick={() => handleCheckboxClick(user)}
                        />
                      </ListItemIcon>
                      <ListItemText id={user.id} primary={ user.isInternal ? (user.name ?? user.userName) : (user.name ?? user.email)} />
                      <Button 
                        size='small'
                        variant='outlined'
                        className='view-user-button'
                        onClick={() => {setSelectedUser(user); setUserDetailsOpen(true)}}
                      >
                        View User Details
                      </Button>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default UserRoles