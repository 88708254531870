import React, { useState, useEffect } from 'react'
// MUI
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
// Other
import IRole from '../../interfaces/roles/IRole';
import NewRoleDialog from './NewRoleDialog';
import List, { listItem } from '../../components/List/List';
import PageHeader from '../../components/PageHeader/PageHeader';
import useAxios from '../../hooks/useAxios';
import ax from 'axios';
import { useNavigate } from 'react-router-dom';


function Roles() {
  const [searchString, setSearchString] = useState<string>('');
  const [roles, setRoles] = useState<IRole[]>([]);
  const [open, setOpen] = useState(false);
  const [updateRole, setUpdateRole] = useState<IRole | undefined>(undefined);
  const axios = useAxios();
  const navigate = useNavigate();

  useEffect(() => {
    loadRoles();
  }, []);

  const loadRoles = async () => {
    try {
      const response = await axios.get<IRole[]>('admin/Role');
      setRoles(response.data);
    } catch (error) {
      if (ax.isAxiosError(error)) {
        if (error.response?.status === 403) {
          navigate('/unauthorized');
          return;
        }
      }
      console.log('An error has occurred while loading the roles.', error);
    }
  }

  function handleAdd(newRole: IRole) {
    const newState = [...roles].concat(newRole);
    setRoles(newState);
  }

  function handleUpdate(updatedRole: IRole) {
    const newState = roles.map(r => {
      return r.name === updatedRole.name ? updatedRole : r;
    });
    setRoles(newState);
  }

  const onAddRoleButtonClick = () => {
    setUpdateRole(undefined);
    setOpen(true);
  }

  function sortByRole(a: IRole, b: IRole) {
    if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) {
      return -1;
    } else if (b.name.toLocaleLowerCase() < a.name.toLocaleLowerCase()) {
      return 1;
    }
    return 0;
  }

  return (
    <>
      <NewRoleDialog open={open} onClose={() => setOpen(false)} updateRole={updateRole} onAdd={handleAdd} onUpdate={handleUpdate} />
      <Stack id='roles-page' direction="column" spacing={2}>
        <PageHeader
          pageName='Roles'
          searchValue={searchString}
          onSearchFieldChange={setSearchString}
          button={{ buttonText: "Add Role", handleButtonClick: onAddRoleButtonClick, startIcon: <AddIcon /> }}
        />
        <List
          listData={
            roles
              .sort((a, b) => sortByRole(a, b))
              .filter((role) => searchString
                ? (role.name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())
                  || role.description?.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()))
                : true
              )
              .map((roleItem): listItem => ({
                title: roleItem.name,
                bodyText: roleItem.description,
                action: {
                  type: 'primary',
                  itemId: roleItem.name,
                  actionText: 'View',
                  actionHandler: () => navigate(roleItem.name),
                  variant: 'outlined',
                  tileClickEnabled: true,
                  buttonVisible: false
                }
              }))
          }
        />
      </Stack>
    </>
  )
}

export default Roles