import React, { useState, useEffect, useMemo } from 'react'
import useAxios from '../../hooks/useAxios';
import "./logs.scss"
import {
    PagingState,
    IntegratedPaging,
    CustomPaging,
    SortingState,
    IntegratedSorting,
    SearchState,
    IntegratedFiltering,
    DataTypeProvider,
    Row,
    TableRowDetailProps,

    
    //TableRowDetail
  } from '@devexpress/dx-react-grid';
  import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    Toolbar,
    SearchPanel,
    DragDropProvider,
    TableColumnReordering,
    TableRowDetail,
    
  } from '@devexpress/dx-react-grid-material-ui';
  // MUI
  import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import { alpha, styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Lock from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
// Interface
import Ilog from '../../interfaces/logs/Ilog';

// Other
import { useQueryParams, userIdQueryParamKey } from '../../hooks/useQueryParams';
// import { IUserSearchParams, useQueryParams, userIdQueryParamKey } from '../../hooks/useQueryParams';
import Searchfield from '../../components/Searchfield/Searchfield';
import ax from 'axios';
// import { useNavigate } from 'react-router-dom';
// import UserClientsDialog from './dialogs/UserClientsDialog';
import Box from '@mui/material/Box';
import { ILoadPanelProps, Sorting } from 'devextreme-react/data-grid';
import ILog from '../../interfaces/logs/Ilog';
import { PageSize } from 'devextreme-react/diagram';
// import { useAuth } from 'react-oidc-context';
// import FormControlLabel from '@mui/material/FormControlLabel';
import { RowDetailState } from '@devexpress/dx-react-grid';
import { AnyAaaaRecord, setDefaultResultOrder } from 'dns';
import { set } from 'lodash';
import DateBox from 'devextreme-react/date-box';
import { createTypeReferenceDirectiveResolutionCache } from 'typescript';

interface RowDetailContentProps {
   row : ILog;
}




function Logs() {
    
  const columns = [
    { name: 'timestamp', title: 'TimeStamp' },
    { name: 'eventId', title: 'EventId' },
    { name: 'loglevel', title: 'Loglevel' },
    {name: 'logger', title: 'Logger'},
    {name: 'message', title: 'Message'}
  ];
  const [tableColumnExtensions] = useState([
    { columnName: 'timestamp', width: 200 },
    { columnName: 'eventId', width: 100 },
    { columnName: 'loglevel', width: 100 },
    { columnName: 'logger', width: 400 },
    
  ]);
  
    const axios = useAxios();
    const[logs, setLogs] = useState<ILog[]>([]);
    const[pageCount, setPageCount] = useState(0);
    const[totalCount, setTotalCount] = useState(0);
    const[pageSize, setPageSize] = useState(50);
    const[currentPage, setCurrentPage] = useState(0);
    const [possibleLoggers, setPossibleLoggers] = useState<string[]>([]);
    const [selectedLogger, setSelectedLogger] = useState<string | null>(null);
    let [searchParams, setSearchParams] = useQueryParams<string>(userIdQueryParamKey);
    const [selectedStartDate, setSelectedStartDate] = useState<Date  | undefined>(undefined);
    const [selectedEndDate, setSelectedEndDate] = useState<Date |undefined>(undefined);
    
    
      
      const loadData = async () => {
      try{
         
          const loggerResponse = await axios.get<string[]>('Log/Loggers');
          setPossibleLoggers(loggerResponse.data);
          
          let query =`Log?&pagesize=${pageSize}&pageindex=${currentPage}`;

          if(selectedStartDate instanceof Date )
          {  

            console.log(selectedStartDate.toISOString());
               query = `${query}&startDate=${selectedStartDate.toISOString()}`
             //query = `${query}&startDate=${utcFormat(selectedStartDate)}`;
             
          }
          if(selectedEndDate instanceof Date)
              query =  `${query}&endDate=${selectedEndDate.toISOString()}`;
          if(selectedLogger!==null)
              query = `${query}&logger=${selectedLogger}`;
         
          const response = await axios.get<ILog[]>(query)
          
          //const response = await axios.get<ILog[]>(`Log?logger=${selectedLogger===null ?'':selectedLogger}&pagesize=${pageSize}&pageindex=${currentPage}`);
          setLogs(response.data);
          setPageCount(Number(response.headers['x-totalpages']));
          setTotalCount(Number(response.headers['x-totalcount']));

      }
      catch(error){

      }
    }
    
    useEffect(() => {
      loadData();
    }, [currentPage, pageSize, selectedLogger, currentPage, selectedStartDate, selectedEndDate ]);
   
    
    const utcFormat =(date : Date) => {
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); 
      const day = String(date.getUTCDate()).padStart(2, '0');
      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');
      const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;


    }
    const unixTimestamp = (date:Date) => Math.floor(date.getTime() / 1000);
    

    
    const ToolbarRootBase = (props: Toolbar.RootProps) => {
      const { children, ...rest } = props
      return (
        <Toolbar.Root {...rest}>
          <Stack justifyContent='space-between' direction={{ xs: 'column', sm: 'row' }} sx={{ width: '100%' }}>
         <div className="form-row">
            <label htmlFor='startDate'>Start Time</label>
            <DateBox  
                id='startDate' 
                type='datetime'   
                displayFormat='yyyy-MM-dd hh:mm:ss a' 
                value={selectedStartDate}
                onValueChanged={(e) =>setSelectedStartDate(e.value)}
                >
  
            </DateBox>
          </div>
          <div className="form-row">
            <label htmlFor='endDate'>End Time</label>
            <DateBox 
                id ="endDate" 
                type='datetime'  
                displayFormat='yyyy-MM-dd hh:mm:ss' 
                value={selectedEndDate}
                onValueChanged={(e => setSelectedEndDate(e.value))} >

            </DateBox>
          </div>
          <Stack direction='row' spacing={1} alignItems='center'>
              {children}
              <Autocomplete
                renderInput={(params) => <TextField {...params} placeholder="Select a logger..." />}
                options={possibleLoggers}
                sx={{ width: 500 }}
                size='small'
                value={selectedLogger}
                onChange={(e, value) => { setSelectedLogger(value); setCurrentPage(0);} }
              />     
          </Stack>
          </Stack>
        </Toolbar.Root>
      );
    }
  
    const RowDetails = (props: TableRowDetail.ContentProps) =>{     
      var row =  props.row;
           
         return  (<div>{row.message}</div>)
    } 



    

    return(
     <div>
          <Box mb={2}>
          <Typography variant='subtitle1'>Logs</Typography>
        </Box>
        <div>
            <Paper>
                <Grid columns={columns} rows={logs}> 
                 <SearchState />
                  <PagingState defaultCurrentPage={currentPage} 
                               defaultPageSize={pageSize}
                               onCurrentPageChange={setCurrentPage} 
                               onPageSizeChange={setPageSize}  />
                  {/* <IntegratedPaging  /> */}
                  {/* <IntegratedSorting />
                  <IntegratedFiltering /> */}
                
                  <CustomPaging totalCount={totalCount}/>
                  <RowDetailState defaultExpandedRowIds={[0]}/>
                  <Table  columnExtensions={tableColumnExtensions} />
                  <TableHeaderRow  />
                  <TableRowDetail contentComponent={RowDetails}  />
                  <Toolbar rootComponent={ToolbarRootBase} />
                  {/* <SearchPanel/> */}
                  <PagingPanel pageSizes={[25,50,75,100]}/>
                </Grid>
            </Paper>
        </div>
     </div>

    )
}

export default Logs
