import React, { useState, useEffect, useContext } from 'react';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import PageHeader from '../../components/PageHeader/PageHeader';
import IApiResource, { IUpdatedApiResource } from '../../interfaces/apiResources/IApiResource';
import List from '../../components/List/List';
import useAxios from '../../hooks/useAxios';
import Typography from '@mui/material/Typography';
import NewApiResource from './NewApiResource';
import IApiResourceClaim from '../../interfaces/apiResources/IApiResourceClaim';
import IClaim from '../../interfaces/claims/IClaim';
import Chip from '@mui/material/Chip';
import ax from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { EnuitAlertContext } from '../../components/alert/EnuitAlert';

function ApiResources() {
  const [search, setSearch] = useState('');
  const [apiResources, setApiResources] = useState<IApiResource[]>([]);
  const [apiResourceClaims, setApiResourceClaims] = useState<IApiResourceClaim[]>([]);
  const [claims, setClaims] = useState<IClaim[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [updateResource, setUpdateResource] = useState<IApiResource | undefined>(undefined);
  const [showDisabledApiResources, setShowDisabledApiResources] = useState(false);
  const axios = useAxios();
  const navigate = useNavigate();
  const theme = useTheme();

  const [alertMessage, setAlertMessage] = useContext(EnuitAlertContext);

  useEffect(() => {
    loadApiResources();
    loadApiResourceClaims();
    loadClaims();
  }, [showDisabledApiResources])

  const loadApiResources = async () => {
    try {
      const response = await axios.get<IApiResource[]>('admin/ApiResource');
      setApiResources(response.data);
    } catch (error) {
      if (ax.isAxiosError(error)) {
        if (error.response?.status === 403) {
          navigate('/unauthorized');
          return;
        }
      }
      console.log('An error occurred while loading the api resources.', error);
    }
  }

  const loadApiResourceClaims = async () => {
    try {
      const response = await axios.get<IApiResourceClaim[]>('admin/ApiClaim/All');
      setApiResourceClaims(response.data);
    } catch (error) {
      console.log('An error occcured while loading the api resource claims.', error);
    }
  }

  const loadClaims = async () => {
    try {
      const response = await axios.get<IClaim[]>('admin/ClaimType');
      setClaims(response.data);
    } catch (error) {
      console.log('There was an error loading the claim types in the api resources page.', error);
    }
  }

  const sortByName = (a: IApiResource, b: IApiResource) => {
    if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) {
      return -1;
    } else if (b.name.toLocaleLowerCase() < a.name.toLocaleLowerCase()) {
      return 1;
    }

    return 0;
  }

  const handleAddNewButtonClick = () => {
    setUpdateResource(undefined);
    setDialogOpen(true);
  }

  const handleEditClick = (id: string) => {
    const resourceToUpdate = apiResources.find(resource => resource.name === id);
    if (resourceToUpdate !== undefined) {
      setUpdateResource(resourceToUpdate);
      setDialogOpen(true);
    }
  }

  interface ICustomBodyProps {
    apiResource: IApiResource;
  }

  const CustomBody = (props: ICustomBodyProps) => {
    const { apiResource } = props;
    return (
      <Stack sx={{ backgroundColor: theme.palette.divider, p: '12px 16px', minHeight: '72px' }} direction='column' spacing={1} >
        <Typography variant='caption'>Claim Types</Typography>
        <Stack direction='row' spacing={1} sx={{ display: 'flex', flexWrap: 'wrap', mt: 1 }}>
          {
            apiResourceClaims.filter(claim => claim.apiName === apiResource.name).map(claim => (
              <Chip label={claim.type} key={`${apiResource.name}-${claim.type}`} sx={{mb: 1}} />
            ))
          }
        </Stack>
      </Stack>
    );
  }

  const handleAdd = (newApiResource: IApiResource, newApiResourceClaims: IApiResourceClaim[]) => {
    setApiResources(prev => [...prev].concat(newApiResource));
    const newClaims = newApiResourceClaims;
    setApiResourceClaims(prev => [...prev].concat(newClaims));
  }

  const handleUpdate = (updatedApiResource: IUpdatedApiResource, updatedResourceClaims: IApiResourceClaim[]) => {
    
    
    setApiResources(prev => prev.map(resource => resource.name === updatedApiResource.name ? updatedApiResource : resource));

    const resourceClaimsExceptThisApi = [...apiResourceClaims].filter(claim => claim.apiName !== updatedApiResource.name);
    const newApiResourceClaims = [...resourceClaimsExceptThisApi].concat(updatedResourceClaims);
    setApiResourceClaims(newApiResourceClaims);
  }

  const handleDelete = (apiResourceName: string) => {
    setApiResources(prev => prev.filter(a => a.name !== apiResourceName));
    setApiResourceClaims(prev => prev.filter(a => a.apiName !== apiResourceName));
  }

  const getUpdateResourceClaims = React.useMemo(() => {
    if (updateResource === undefined) {
      return [];
    }

    return apiResourceClaims.filter(resource => resource.apiName === updateResource?.name);
  }, [updateResource, showDisabledApiResources]);

  const filterApiResources = (resource: IApiResource) => {
    if (search === '') {
      return showDisabledApiResources ?
        true
       :
        resource.enabled === true;
      
    }

    return  showDisabledApiResources ? 
    (
      resource.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
      resource.description?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
      resource.displayName?.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    ) 
    :
    (
      resource.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
      resource.description?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
      resource.displayName?.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    ) && resource.enabled === true
  }


  return (
    <>
      <NewApiResource
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onAdd={handleAdd}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        //onDisable ={handleUpdate}
        updateResource={updateResource}
        updateResourceClaims={getUpdateResourceClaims}
        claims={claims}
        setAlertMessage={setAlertMessage}
      />
      <Stack id='api-resources-page' direction='column' spacing={2}>
        <PageHeader
          pageName='Api Resources'
          searchValue={search}
          onSearchFieldChange={setSearch}
          button={{ buttonText: "Add Api Resource", handleButtonClick: handleAddNewButtonClick, startIcon: <AddIcon /> }}
          chekbox= {{ label: 'Show disabled Api Resource', handleCheckboxClick : () =>{}, enable: true}}
          onChekboxChange={setShowDisabledApiResources}
        />
        <List
          minHeight='120px'
          listData={
            apiResources
              .filter(resource => filterApiResources(resource))
              .sort((a, b) => sortByName(a, b))
              .map(apiResource => ({
                title: apiResource.name,
                bodyText: apiResource.displayName,
                body: <CustomBody apiResource={apiResource} />,
                action: {
                  type: 'primary',
                  itemId: apiResource.name,
                  actionText: 'Edit',
                  actionHandler: handleEditClick,
                  variant: 'outlined',
                  tileClickEnabled: true,
                  buttonVisible: false
                }
              }))
          }
        />
      </Stack>
    </>
  )
}

export default ApiResources
